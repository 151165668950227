import { LoginCallback, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { BrowserRouter as Router, Route, useHistory } from 'react-router-dom';

import Home from './Home.js';
import Reverse from './Reverse.js';
// import Google from './googleAuth.js';
// import Apple from './appleAuth.js';

// import Admin from './Admin.js';
import Login from './Login.js';

const oktaAuthProvider = new OktaAuth({
    issuer: process.env.REACT_APP_OKTA_BASE_URL,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/login/callback`,
});

function RouterApp() {
    const history = useHistory();
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    return (
        <Router>
            <Route path='/' exact={true} component={Home} />
            <Route path='/c/*' exact={true} component={Home} />
            <Route path='/auth' exact={true} component={Reverse} />
            {/* <Route path='/auth/google' exact={true} component={Google} /> */}
            {/* <Route path='/auth/apple' exact={true} component={Apple} /> */}

            <Security oktaAuth={oktaAuthProvider} restoreOriginalUri={restoreOriginalUri}>
                {/* <SecureRoute path='/admin' exact={true} component={Admin} /> */}
                <Route path='/login/callback' component={LoginCallback} />
                <Route path='/login' component={Login} />
            </Security>
        </Router>
    );
}

export default RouterApp;