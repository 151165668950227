import { useOktaAuth } from "@okta/okta-react";
// import { useEffect } from "react";

function Login() {
    const { oktaAuth } = useOktaAuth();

    const signInWithOkta = async () => {
        oktaAuth.signInWithRedirect({ originalUri: '/admin' });
    }

    return (
        <div className='Login'>
            <button className='text-3xl' onClick={() => signInWithOkta()}>Login</button>
        </div>
    )
}

export default Login;