import ecLogo from "./assets/EC-LogoFullColor.svg";
import gLogo from "./assets/Google-LogoFullColor.svg";
import aLogo from "./assets/Apple-LogoNoColor.svg";

import { googleSSO, appleSSO } from "./fbAuth.js";

function Reverse() {
  async function connectToGoogleSSO() {
    let response = await googleSSO();
    if (response[0] === "success") {
      console.log(response[1]);
      window.location.replace(
        `https://formstack.io/5C744?Connection_Card__c.First_Name__c=${response[1].firstName}&Connection_Card__c.Last_Name__c=${response[1].lastName}&Connection_Card__c.Email__c=${response[1].emailAddress}&Connection_Card__c.Phone__c=${response[1].phoneNumber}&source=TapTech`
      );
    }
  }

  async function connectToAppleSSO() {
    let response = await appleSSO();
    if (response[0] === "success") {
      window.location.replace(
        `https://formstack.io/5C744?Connection_Card__c.First_Name__c=${response[1].firstName}&Connection_Card__c.Last_Name__c=${response[1].lastName}&Connection_Card__c.Email__c=${response[1].emailAddress}&Connection_Card__c.Phone__c=${response[1].phoneNumber}&source=TapTech`
      );
    }
  }

  return (
    <div className="Home">
      <div className="w-screen h-screen flex flex-col justify-start items-center">
        <div className="w-full pt-5 px-5 flex flex-row justify-start items-center">
          <a
            className="w-fit h-fit block"
            href="https://elevationchurch.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-20" src={ecLogo} alt="Elevation Church" />
          </a>
        </div>
        <div className="w-full h-fit py-5">
          <div className="w-full h-[0.15rem] bg-[#F2F2F2]" />
        </div>
        <div className="w-full h-full lg:w-[25%] xl:w-[50%] pb-8 px-5 md:px-16 flex flex-col items-center justify-center gap-5">
          <button
            className="w-full h-12 rounded-full bg-[#F2F2F2] flex flex-row items-center justify-center gap-3 px-3"
            onClick={() => connectToGoogleSSO()}
          >
            <img className="h-7 aspect-square" src={gLogo} alt="Google" />
            <h1 className="text-black font-bold text-md sm:text-lg md:text-lg xl:text-lg whitespace-nowrap">
              Connect through Google
            </h1>
          </button>
          <button
            className="w-full h-12 rounded-full bg-[#000000] flex flex-row items-center justify-center gap-3 px-3"
            onClick={() => connectToAppleSSO()}
          >
            <img className="h-6" src={aLogo} alt="Apple" />
            <h1 className="text-white font-bold text-md sm:text-lg md:text-lg xl:text-lg whitespace-nowrap">
              Connect through Apple
            </h1>
          </button>
          <a
            className="w-full h-12 rounded-full bg-[#FF671F] flex flex-row items-center justify-center gap-3 px-3"
            href="https://formstack.io/5C744"
            target="_blank"
            rel="noreferrer"
          >
            <h1 className="text-white font-bold text-md sm:text-lg md:text-lg xl:text-lg whitespace-nowrap">
              Continue without signing in
            </h1>
          </a>
        </div>
        <h3 className="text-[#00000050] font-normal text-xs mt-auto pb-8">
          © 2024 Elevation Church.{" "}
          <span className="font-medium">All Rights Reserved.</span>
        </h3>
      </div>
    </div>
  );
}

export default Reverse;
