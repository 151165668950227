import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBHAZDqDslXe8gEloXLqv1hPDKhkGuQYVw",
  authDomain: "tap.elevationchurch.org",
  projectId: "nfc-connect-4addd",
  storageBucket: "nfc-connect-4addd.appspot.com",
  messagingSenderId: "638797925669",
  appId: "1:638797925669:web:77496880da204acb7e1ecc"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then((result) => {
    const displayName = result.user.displayName;
  }).catch((error) => {
    console.log(error);
  });
}

// if (window.location.hostname === 'localhost') {
//     connectFunctionsEmulator(functions, 'localhost', 5001)
// }