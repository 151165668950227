import { useEffect, useState } from "react";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from "./firebaseConfig.js";

import ecLogo from "./assets/EC-LogoFullColor.svg";

// Connection_Card__c.Campus_Picklist__c=Ballantyne

function Home() {
  const [data, setData] = useState([]);
  const [campus, setCampus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   const checkSettings = onSnapshot(doc(db, 'globalSettings/settings'), snapshot => {
  //     if (snapshot.data()?.bypass) {
  //       window.location.replace(snapshot.data()?.bypassURL);
  //     } else {
  //       setBypass(false);
  //     }
  //   });

  //   return () => {
  //     checkSettings();
  //   }
  // }, []);

  useEffect(() => {
    var pathArray = window.location.pathname.split("/");
    setCampus(pathArray[2] || "default");

    const getData = onSnapshot(
      query(collection(db, "global"), where("visible", "==", true)),
      (snapshot) => {
        console.log(snapshot);

        if (snapshot.empty) {
          return;
        }

        let snapshotData = snapshot.docs.map((res) => ({
          ...res.data(),
          id: res.id,
        }));
        snapshotData.sort((a, b) => a.order - b.order);

        console.log(snapshotData);
        setData(snapshotData);
      }
    );

    const checkSettings = onSnapshot(
      doc(db, "globalSettings/settings"),
      (snapshot) => {
        if (snapshot.data()?.bypass) {
          window.location.replace(snapshot.data()?.bypassURL);
        } else {
          setIsLoading(false);
        }
      }
    );

    return () => {
      getData();
      checkSettings();
    };
  }, []);

  if (isLoading) {
    return (
      <div className="Home">
        <div className="w-screen h-screen flex flex-col justify-start items-center">
          <div className="w-full pt-5 px-5 flex flex-row justify-start items-center">
            <a
              className="w-fit h-fit block"
              href="https://elevationchurch.org/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="w-20" src={ecLogo} alt="Elevation Church" />
            </a>
          </div>
          <div className="w-full h-fit py-5">
            <div className="w-full h-[0.15rem] bg-[#F2F2F2]" />
          </div>
          <div className="w-full h-fit lg:w-[75%] xl:w-[60%] pb-8 px-5 md:px-16 grid grid-cols-1 md:grid-cols-2 gap-5" />
          <h3 className="text-[#00000050] font-normal text-xs mt-auto pb-8">
            © 2024 Elevation Church.{" "}
            <span className="font-medium">All Rights Reserved.</span>
          </h3>
        </div>
      </div>
    );
  }

  return (
    <div className="Home">
      <div className="w-screen h-screen flex flex-col justify-start items-center">
        <div className="w-full pt-5 px-5 flex flex-row justify-start items-center">
          <a
            className="w-fit h-fit block"
            href="https://elevationchurch.org/"
            target="_blank"
            rel="noreferrer"
          >
            <img className="w-20" src={ecLogo} alt="Elevation Church" />
          </a>
        </div>
        <div className="w-full h-fit py-5">
          <div className="w-full h-[0.15rem] bg-[#F2F2F2]" />
        </div>
        <div className="w-full h-fit lg:w-[75%] xl:w-[60%] pb-8 px-5 md:px-16 grid grid-cols-1 md:grid-cols-2 gap-5">
          {data.map((dat) => {
            return (
              <a
                key={dat.id}
                style={{ "--image-url": `url(${dat.bgImage})` }}
                className="block w-full aspect-video bg-[image:var(--image-url)] bg-cover bg-no-repeat rounded-2xl"
                href={dat.link[campus]}
                target="_blank"
                rel="noreferrer"
              >
                <div className="w-full h-full bg-gradient-to-t from-black via-transparent to-transparent rounded-2xl flex flex-col justify-end items-start p-5">
                  <h1 className="text-white font-bold text-lg sm:text-xl md:text-xl xl:text-xl text-left">
                    {dat.title}
                  </h1>
                  <h2 className="text-white text-start font-normal text-[10px]/[12px] sm:text-[10px]/[12px] md:text-[10px]/[12px] xl:text-[11px]/[13px]">
                    {dat.description}
                  </h2>
                </div>
              </a>
            );
          })}
        </div>
        <h3 className="text-[#00000050] font-normal text-xs mt-auto pb-8">
          © 2024 Elevation Church.{" "}
          <span className="font-medium">All Rights Reserved.</span>
        </h3>
      </div>
    </div>
  );
}

export default Home;
