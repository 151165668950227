import { auth } from "./firebaseConfig.js";
import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";

const googleProvider = new GoogleAuthProvider();
const appleProvider = new OAuthProvider("apple.com");
appleProvider.addScope("name");
appleProvider.addScope("email");

export async function googleSSO() {
  let response = "";

  await signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      let displayName = result.user?.displayName || "";
      let firstName = "";
      let lastName = "";
      if (displayName !== "") {
        firstName = displayName.substring(0, displayName.indexOf(" "));
        lastName = displayName.substring(displayName.indexOf(" ") + 1);
      }

      let newUserData = {
        displayName: displayName,
        firstName: firstName,
        lastName: lastName,
        emailAddress: result.user?.email || "",
        phoneNumber: result.user?.phoneNumber || "",
      };

      response = ["success", newUserData];
    })
    .catch((error) => {
      let errorCode = error.code;
      let errorMessage = error.message;
      console.log(`Error Code: ${errorCode} | Error Message: ${errorMessage}`);

      response = "critical error";
      return;
    });

  return response;
}

export async function appleSSO() {
  let response = "";

  await signInWithPopup(auth, appleProvider)
    .then(async (result) => {
      const credential = OAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;
      const idToken = credential.idToken;

      console.log(result.user);

      let displayName = result.user?.displayName || "";
      let firstName = "";
      let lastName = "";
      if (displayName !== "") {
        firstName = displayName.substring(0, displayName.indexOf(" "));
        lastName = displayName.substring(displayName.indexOf(" ") + 1);
      }

      let newUserData = {
        displayName: displayName,
        firstName: firstName,
        lastName: lastName,
        emailAddress: result.user?.email || "",
        phoneNumber: result.user?.phoneNumber || "",
      };

      response = ["success", newUserData];
    })
    .catch((error) => {
      let errorCode = error.code;
      let errorMessage = error.message;
      let email = error.customData.email;
      let credential = OAuthProvider.credentialFromError(error);
      console.log(`Error Code: ${errorCode} | Error Message: ${errorMessage}`);

      response = "critical error";
      return;
    });

  return response;
}
